import { render, staticRenderFns } from "./EditLessons.vue?vue&type=template&id=367240d0&scoped=true&"
import script from "./EditLessons.vue?vue&type=script&lang=js&"
export * from "./EditLessons.vue?vue&type=script&lang=js&"
import style0 from "./EditLessons.vue?vue&type=style&index=0&id=367240d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "367240d0",
  null
  
)

/* custom blocks */
import block0 from "./EditLessons.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=vue-simple-markdown%2Fdist%2Fvue-simple-markdown.css"
if (typeof block0 === 'function') block0(component)

export default component.exports