<template>
  <v-app>
    <div v-if="this.signedIn" class="contentContainer">

      <h1>Edit Lessons</h1>

      <div style="text-align:center;margin:0 0 20px 0; padding:10px 20px 0 20px;border: 2px solid #343a40; border-radius:20px;">
        <h3>Instructions</h3>
        <p>Select the lesson you want to view / edit &rarr; choose the case and gender / type.</p>
        <p>Type in markdown, preview on the right &rarr; <a style="text-decoration:underline" href="https://www.npmjs.com/package/vue-simple-markdown">click here for syntax help</a></p>
      </div>

      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="5">
            <v-select hide-details tile outlined filled :items="casesList" label="Choose Case" v-model="caseChosen" ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="5">
            <v-select hide-details tile outlined filled :items="getLessonList()" label="Choose Lesson" v-model="lessonChosen" ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="2" >
            <v-btn dense hide-details x-large elevation=0 color="green lighten-3" height=56px @click="fetchLesson">Get Lesson</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <hr>

      <v-row>
        <v-col class="d-flex" cols="6">
          <v-textarea no-resize height="80vh" outlined v-model="source"></v-textarea>
        </v-col>
        <v-col class="d-flex" cols="6">
          <div class="resultingHTML">
            <vue-simple-markdown :source="source"></vue-simple-markdown>
          </div>

        </v-col>

      </v-row>

      <div class="buttons">
        <v-btn :disabled="checkDisabled()" dense hide-details x-large elevation=0 color="green lighten-3" height=56px @click="saveLesson">Save Lesson</v-btn>
      </div>

    </div>


  </v-app>
</template>

<style scoped>
  h1{
    margin: 0 0 20px 0;
  }

  hr {
    border: 5px solid #383838;
    border-radius: 2px;
    margin: 10px 0 20px 0;
  }

  .buttons {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .contentContainer {
    width: 1000px;
    margin: auto;
    margin-top: 0;
    padding: 20px;
    text-align: left;
  }

  .resultingHTML {
    width: 100%;
    height: 80vh;
    padding: 2px 10px;
    overflow: scroll;
    background-color: #fff;
    border: 1px solid#ccc;
    border-radius: 4px;
    overflow-x: hidden;
  }

  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #000 !important;
    font-weight: bold;
  }
</style>



<script>
  import {db} from '../firebase/db';
  import firebase from 'firebase';

  export default {
    title: "Edit Lessons",
    data() {
      return {
        signedIn: false,
        source: "",
        lessonContent: "",
        originalFetchedLesson: "",
        caseChosen: "",
        lessonChosen: "",
        casesList: ['Nominative', 'Accusative', 'Prepositional', 'Genitive', 'Dative', 'Instrumental'],
        lessonsList: ['1 Nouns - Masculine',
                      '2 Adjectives - Masculine',
                      '3 Nouns - Feminine',
                      '4 Adjectives - Feminine',
                      '5 Nouns - Neuter',
                      '6 Adjectives - Neuter',
                      '7 Adjectives - Plural'],
        nominativeLessonsList: ['1 Identify grammatical gender',
                                '2 Nouns',
                                '3 Adjectives']
      }
    },
    methods: {

      getLessonList(){
        if (this.caseChosen == 'Nominative') {
          return this.nominativeLessonsList
        } else {
          return this.lessonsList
        }
      },

      toggleAllInputs() {
        this.inputsDisabled = !this.inputsDisabled;
        return this.inputsDisabled;
      },

      async fetchLesson() {
        var lessonContent = await db.collection('Lessons').doc('Lessons').collection(this.caseChosen).doc(this.lessonChosen.substring(0, 1)).get();
        this.source = lessonContent.data().content;
        this.originalFetchedLesson = lessonContent.data().content;
      },

      async saveLesson(){
        await db.collection('Lessons').doc('Lessons').collection(this.caseChosen).doc(this.lessonChosen.substring(0, 1)).update({
          content: this.source
        });
        this.originalFetchedLesson = this.source;
      },

      checkDisabled() {
        return (this.source == this.originalFetchedLesson) ? true : false;
      },

      async checkIfAdmin() {
        var user = await db.collection("Users").doc(this.uid).get();
        user = user.data();

        if (!user.admin) {
          this.$router.push({ name: 'Index'})
        }
        // return user.admin;
      }

    },
    async beforeMount() {


      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.uid = user.uid;
          this.signedIn = true;
          this.username = user.displayName;

          this.checkIfAdmin(user.uid);


        } else {
          // User is signed out
          // ...
          this.signedIn = false;
          this.$router.push({ name: 'Index'})
        }
      });
    }
  }
</script>


<!-- Include after Vue -->
<!-- Local files -->
<link rel="stylesheet" href="vue-simple-markdown/dist/vue-simple-markdown.css">
<script src="vue-simple-markdown/dist/vue-simple-markdown.js"></script>

<!-- From CDN -->
<link rel="stylesheet" href="https://unpkg.com/vue-simple-markdown/dist/vue-simple-markdown.css">
<script src="https://unpkg.com/vue-simple-markdown"></script>










<!--  -->
